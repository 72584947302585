/*------------------------------------------------------------------
	[  Login Form ]
-------------------------------------------------------------------*/
.login-page{
  body{
    background-color: #fff;
  }
}
.login-box{
  border: solid 1px #e6eaee;
  border-radius: 4px;

  .login-box-header{
    background-color: #f8fafc;
    border-bottom: solid 1px #e6eaee;
    text-align: center;

    h1{
      font-family: $font-sansPro;
      font-size: 24px;
      text-align: center;
      color: #354052;
      padding: 20px 30px;
      margin: 0;
    }
  }
  .form-field{
    .field-label{
      font-family: $font-sansPro;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.36;
      text-align: left;
      color: #7f8fa4;
    }

    .field-input{
      input{
        border-radius: 4px;
        background-color: #f8fafc !important;
        border: solid 1px #dfe3e9 !important;
        font-family: $font-sansPro;
        font-size: 14px !important;
        font-weight: 600 !important;
        line-height: 1.36;
        text-align: left;
        color: #354052;
        padding: 11px 20px;
      }

      select{
        border-radius: 4px;
        background-color: #f8fafc !important;
        border: solid 1px #dfe3e9 !important;
        font-family: $font-sansPro;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.36;
        text-align: left;
        color: #354052;
        padding: 11px 20px;
        option{
          padding: 6px 15px;
        }
      }

    }
    .btn{
      width: 100% !important;
    }
  }

  .login-box-body{
     padding: 18px 30px;
  }

  .login-box-footer {
    border-top: solid 1px #e6eaee;
    text-align: center;
    padding: 30px 30px;
    position: relative;

    .forgot-password{
      font-family: $font-sansPro;
      font-size: 14px;
      line-height: 1.36;
      color: #7f8fa4;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      top: -10px;
      width: 200px;
      background-color: #fff;
    }
  }
}

.main-content{

  h1{
    border-right: 1px solid #cccccc;
    display: inline;
    margin: 0;
    padding-right: 12px;
    font-size: 24px;
  }
}

.search-input{
  position: relative;
  button{
    position: absolute;
    top: 7px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;

    i{
      color: #a1a7af;
      font-size: 16px;
    }
  }
}

#addStaffModal{
  .modal-header{
    border: none;
    padding: 10px 15px;
  }
  .modal-body{
    padding: 0 30px 30px;
  }
  .form-field{
    .field-label{
      font-family: $font-proxinova-semibold;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.36;
      text-align: left;
      color: #7f8fa4;
    }
    .field-input{
      margin-bottom: 15px;
      input{
        border-radius: 4px;
        background-color: #f8fafc;
        border: solid 1px #dfe3e9;
        font-family: $font-proxinova-semibold;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.36;
        text-align: left;
        color: #354052;
      }
      select{
        border-radius: 4px;
        background-color: #f8fafc;
        border: solid 1px #dfe3e9;
        font-family: $font-proxinova-semibold;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.36;
        text-align: left;
        color: #354052;
        padding: 12px 15px;
        option{
          padding: 6px 15px;
        }
      }
    }
  }
}

.content-wraper{
  background-color: #fff;
  padding: 22px 15px;
}

#debtorData{
  margin-top: 40px;

  ul{
    border: 1px solid #f0f0f0;

    .nav-item{
        .nav-link{
          border: none;
          border-radius: 0;
          color: #000;

          &:hover, &.active, &.active:focus{
            background-color: $green;
            color: #fff;
          }
        }
      + .nav-item{
         margin-left: 0;
          .nav-link{
            border-left: 1px solid #f0f0f0 !important;
          }
      }
    }
  }

  .tab-content {
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    padding: 30px 15px;
  }
}

#spillKitTable_length{
  display: none;
}
#spillKitTable_filter{
  display: none;
}

#formDetails {
  padding-top: 0;

  table {
    td, th{
      border: 1px solid #d9d9d9 !important;
      color: #000;
    }
  }
  img{
    max-width: 100%;
  }
  h2,h3{
    color: #000;
  }

  .itemImage{
    margin-bottom: 15px;
  }
}