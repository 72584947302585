header{
  .navbar {
    display: inline-block !important;
    background: none !important;
    padding: 4px 0 0 0 !important;

    .navbar-toggler{
      display: none;
    }

    .fa-bars{
      color:white;
    }

    .navbar-toggle {
      float: none;
    }

    .navbar-nav li{
      span.dropdown-toggle {
        position: relative;
        &:after {
          content: '\35';
          font-size: 30px;
          font-family: 'ElegantIcons';
          color:white;
        }
      }
      &.open {
        &>span.dropdown-toggle {
          &:after {
            content: '\33';
          }
        }
      }
      .dropdown-menu {
        background-color: #231f20;
        li:last-child {
          border-bottom: none;
        }
        a {
          border: none;
          &:hover,
          &:active,
          &:focus{
            background-color: inherit;
            border: none;
          }
        }
      }

      a{
        display:inline-block;
      }
    }
  }

  #top-head{
    background-image: linear-gradient(to top, #2ea1f8, #1990ea);
    padding: 13px 0;

    #navbarNavDropdown{
      display: block;

      ul{
        margin: 0;
        list-style: none;

        li{
          a{
            font-family: $font-proxinova-light;
            font-size: 14px;
            font-weight: 600;
            text-align: left;
            color: #ffffff;
          }

          + li{
            margin-left: 32px;
          }
        }
      }
    }
  }
}



.profile-menu{
  margin: 0;
  text-align: right;
  padding: 0;

  .my-profile-dropdown{
    left: auto;
    right: 20px;

    ul{
      list-style: none;
      padding: 0;

      li{
        a{
          padding: 5px 15px;
          color: #333;
          display: block;
          font-family: $font-proxinova-light;
          font-size: 14px;
        }

        &:hover, &.active{
            background-color: #0275d8;

            a{
              color: #fff;
            }
        }
      }
    }
  }

  >li{
    text-align: right;
    display: inline-block;
    i{

    }
  }
}

#breadcrumb-section{
  background-color: #fff;
  padding: 23px 0;
  margin-bottom: 70px;

  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
      display: inline-block;
      font-family: $font-proxinova-light;
      font-size: 14px;
      text-align: left;
      color: #7f8fa4;
      border-left: 1px solid #dfe3e9;
      margin-left: 10px;
      padding-left: 10px;

      &:first-child{
        font-family: $font-proxinova-bold;
        font-size: 18px;
        font-weight: normal;
        text-align: left;
        color: #354052;
        border: 0;
        margin: 0;
        padding-left: 0;
      }
    }
  }

  .btn{
    padding: 10px 15px;
    font-family: $font-proxinova-semibold;
    font-weight: normal;
    margin: 0;
    i{
      margin-right: 20px;
    }
  }
}


