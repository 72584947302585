@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

@font-face {
  font-family: 'Font-awesome';
  src: url('../libs/font-awesome/fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),  url('../fonts/fontawesome-webfont.woff') format('woff'), url('../fonts/fontawesome-webfont.ttf')  format('truetype'), url('../fonts/fontawesome-webfont.svg#font-awesome') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Light';
  src: url('../fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Light.otf')  format('opentype'),
  url('../fonts/ProximaNova-Light.woff') format('woff'), url('../fonts/ProximaNova-Light.ttf')  format('truetype'), url('../fonts/ProximaNova-Light.svg#ProximaNova-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Regular.otf')  format('opentype'),
	     url('../fonts/ProximaNova-Regular.woff') format('woff'), url('../fonts/ProximaNova-Regular.ttf')  format('truetype'), url('../fonts/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Semibold';
  src: url('../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Semibold.otf')  format('opentype'),
  url('../fonts/ProximaNova-Semibold.woff') format('woff'), url('../fonts/ProximaNova-Semibold.ttf')  format('truetype'), url('../fonts/ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Bold.otf')  format('opentype'),
	     url('../fonts/ProximaNova-Bold.woff') format('woff'), url('../fonts/ProximaNova-Bold.ttf')  format('truetype'), url('../fonts/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Black';
  src: url('../fonts/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Black.otf')  format('opentype'),
  url('../fonts/ProximaNova-Black.woff') format('woff'), url('../fonts/ProximaNova-Black.ttf')  format('truetype'), url('../fonts/ProximaNova-Black.svg#ProximaNova-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* MEDIA QUERIES SCREEN SIZES */
$xsmall-devices		: 480px;
$small-devices		: 767px;
$medium-devices		: 991px;
$large-devices		: 1199px;
$xlarge-devices		: 1280px;

/* URL's */
$img-url			: '../images/';

/* Fonts / Sizes */
$font-default		      : 'Lato', sans-serif;
$font-sansPro             : 'Source Sans Pro', sans-serif;
$font-proxinova-light     : 'ProximaNova-Light';
$font-proxinova-bold      : 'ProximaNova-Bold';
$font-proxinova-semibold  : 'ProximaNova-Semibold';
$font-proxinova-black     : 'ProximaNova-Black';
$font-roboto              : 'Roboto';


/* Primary COLORS */
$colo-default		: #21416b;
$dark-gray			: #fdfdfd;
$blue               : #0D4464;
$powder-blue        : #b9ddfb;
$orange		        : #ff5607;
$green              : #39b54a;

/* Status Message COLORS */
$error-color		: #ed171f;
$success-color      : #169207;


$enable-flex: true;

/* Global Styles */
html{ 
	overflow-y: scroll;	
}
body{
  background-color: #eff3f6;
	font-family: $font-sansPro !important;
}
*{
    outline: none !important;
}
.container .row {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

a{
    text-decoration: none !important;
}
.clearBoth{
    clear: both;
    float: none;
}
h1{
    color: $colo-default ;
    font-family: $font-proxinova-light;
    font-size: 32px;
    margin-bottom: 10px;
    margin-top: 36px;
    text-transform: capitalize;
}
h2{
    color: $colo-default ;
    font-family: $font-proxinova-light;
    font-size: 28px;
    margin-bottom: 20px;
}
h3{
    color: $colo-default ;
    font-family: $font-proxinova-light;
    font-size: 24px;
    margin-bottom: 20px;
}
h4{
    color: $colo-default ;
    font-family: $font-proxinova-light;
    font-size: 20px;
    margin-bottom: 20px;
}
h5{
    color: $colo-default ;
    font-family: $font-proxinova-light;
    font-size: 18px;
    margin-bottom: 20px; 
}
h6{
    color: $colo-default ;
    font-family: $font-proxinova-light;
    font-size: 16px;
    margin-bottom: 20px; 
}

.page__content{   
    
    >p, >h2, >h3, >h4, >h5, >h6{
        margin-top: 0;
    }
    p{
        color: $colo-default;
        font-family: $font-default;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 19px;
        
        a{
            color: $blue;
        }
    } 
    strong{
        color: $dark-gray;
    }
    .post__featured{
        margin-bottom: 0;
    }
    
    /* Featured Image*/
    
    img{
        float: left;
        margin-bottom: 20px;
        margin-right: 30px;
        margin-top: 4px;
        max-width: 100%;
        height: auto;
    }
    
    /* Order/ UnOrder list*/
    >ul{
        margin-left: 95px;
        margin-bottom: 20px;        
        
    }
    
    ul{
        overflow: hidden;
        list-style: none;
        >li{
            color: $colo-default;
            font-family: $font-default;
            font-size: 12px;
            margin-top: 14px;
            margin-bottom: 0;
            
            &:before{
                color: $dark-gray;
                content: "\f101";
                font-family: FontAwesome;
                margin-left: -38px;
                margin-right: 5px;
            }
            
            a{
                color: $blue;
                text-decoration: none;
            }
            
            &:first-child{
                margin-top: 0;
            }
        }
        
        
    }
    
    ol{ 
        @extend ul;
        list-style: outside decimal;
        
        >li{
            &:before{
                content:"";
                margin: 0;
            }
        }
        ol{
            list-style: outside lower-alpha;
            padding-left: 20px;            
            
        }
    }
    
    .attachment-contact-thumb{
        float: none;
        margin-right: 0;
    }
    
}

.btn{
	margin-bottom: 14px;
    font-family: $font-sansPro;
    font-weight: 600;
    color: #fff !important;
    border: 1px solid $blue;
    padding: 13px 30px;
    border-radius: 4px;
    text-transform: capitalize;
    text-align: center;

    &:hover{
      background-color: $blue;
      color: #fff !important;
    }

  &.btn-blue{
    background-color: $blue;
    color: #fff !important;

    &:hover{
      background-color: #fff;
      color: $blue !important;
    }
  }

  &.btn-green{
    background-color: $green;
    background-image: linear-gradient(to bottom, #39b54a, #34aa44 98%);
    border: solid 1px #249533;
    color: #fff !important;

    &:hover{
      background-color: #fff;
      color: #fff !important;
    }
  }

  &.btn-orange{
    background-color: $orange;
    color: #fff !important;
    border: 1px solid $orange;

    &:hover{
      background-color: #fff;
      color: $orange !important;
    }
  }
  &.btn-red{
    background-color: #d0011b;
    color: #fff !important;
    border: 1px solid #d0011b;

    &:hover{
      background-color: #fff;
      color: #d0011b !important;
    }
  }

}
.btn + .btn{
	margin-left: 15px;
}

button + button {
    margin-left: 15px;
}


.cyx-breadcrumb{
    background: none;
    margin-top: -11px;
    margin-bottom: 2px;
    padding-left: 0;
    color: $font-default;
    font-family: $font-default;
    font-weight: bold;
    font-size: 15px;
    
    i{
        margin: 0 2px;
        color: $blue;
    }
    
    span{
        color: $font-default;
        font-family: $font-default;
        font-size: 15px;
        margin-left: 1px;
        margin-right: 3px;
        
       
    }
    a {
        color: $blue;
        font-family: $font-default;
        font-size: 15px;
        
        span{
            color: $blue;
            font-family: $font-default;
            font-size: 15px;
        }
    }
}

.pagination{
    text-align: center;
    display: table;
    margin-top: 15px !important;

    .page-numbers{
        color: #0275d8;
        background: #fff;
        border: 1px solid #e6e6e6;
        display: table-cell;
        font-family: $font-proxinova-light;
        font-size: 16px;
        text-align: center;
        text-decoration: none;
        border-right: 1px solid #eceeef;
        border-top: 1px solid #eceeef;
        border-bottom: 1px solid #eceeef;
        border-left: 0;
        cursor: pointer;
        padding: 6px 16px;
        
        &.prev{
            margin-right: 32px;
        }
        &.next{
            margin-left: 32px;
        }

        &.current, &:hover, &.active{
          background-color: #0275d8;
          color: #ffffff;
          padding: 6px 16px;
          border-color: #0275d8;

          a{
            color: #fff;
          }
        }
    }
}

.full-height-viewport{
	height: 100vh;
    min-height: 600px;
}

/*---------------------------------------------
     [System messages]
----------------------------------------------*/
.cl-system-messages{
	font-size: 14px;
	font-weight: normal;
    margin-bottom: 15px;
}
.cl-alert {
	background-color: #e1f2fb;
	background-position: 15px center;
	background-repeat: no-repeat;
	border-radius: 2px;
	color: #fff;
	margin-bottom: 20px;
	padding: 10px 10px 10px 50px;
	text-align: left;
}
.error-message {
    background-color: $error-color;
    color: #fff;
    padding: 7px 15px;
    border-radius: 4px;
}
.success-message {
    background-color: $success-color;
	border: solid 1px #d0e9c6;
	text-align: center;
  	color: #fff;
	font-weight: normal;
	font-size: 16px;
	margin: 10px 0;
	padding: 10px;
    padding: 7px 15px;
    border-radius: 4px;
}
.warning-message {
    background-color: #f89406;	
	text-align: center;
    color: #fff;
    padding: 7px 15px;
    border-radius: 4px;
}
.info-message {
    background-color: #2f96b4;
    color: #fff;
    padding: 7px 15px;
    border-radius: 4px;
}


/*---------------------------------------------
   [Animations]
--------------------------------------------*/
@keyframes cl-shake {
	0%, 100% {
		transform: translateX(0px);
	}
	10% {
		transform: translateX(-9px);
	}
	20% {
		transform: translateX(8px);
	}
	30% {
		transform: translateX(-7px);
	}
	40% {
		transform: translateX(6px);
	}
	50% {
		transform: translateX(-5px);
	}
	60% {
		transform: translateX(4px);
	}
	70% {
		transform: translateX(-3px);
	}
	80% {
		transform: translateX(2px);
	}
	90% {
		transform: translateX(-1px);
	}
}
[class*="clss-animation-"] {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
}

.cl-animation-shake {
    animation-name: uk-shake;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  transition: .3s ease-out;
}

/*------------------
    [ Center OR Middle Bootstrap Columns ]
--------------------------*/
.cl-flex{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.cl-flex-center{
  justify-content: center;
}
.cl-flex-middle{
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}

/*-------------------------
   Form Validations
---------------------------*/
.form-validaion{
	label.error{
		color: $error-color;
		font-family: $font-default;
		font-size: 12px;
		line-height: normal;
		padding: 0 15px;
	}
}

//Switch Button
.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;

  &:before {
    content: "ON";
    width: 10px;
    top: 6px;
    left: 10px;
    position: relative;
    font-size: 13px;
    color: #fff;
    z-index: 9;
  }
  &:after {
    content: "OFF";
    width: 10px;
    top: 6px;
    left: 25px;
    position: relative;
    font-size: 13px;
    color: #fff;
  }

  input {
    display:none;

    &:checked + .slider {
      background-color: #a6e50f;
    }
    &:focus + .slider {
      box-shadow: 0 0 1px #a6e50f;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(40px);
      -ms-transform: translateX(40px);
      transform: translateX(40px);
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #939ea7;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 8px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }
}

/*--------------------------
   [ Default Form ]
----------------------------*/

form{
  .field-section{
    + .field-section{
      margin-top: 60px;
    }
    .field-section-title{
      font-family: $font-default;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0;
      text-align: left;
      color: #162944;
      margin-bottom: 16px;
    }
  }
  .form-field{
      .field-label{
          color: $blue;
          font-family: $font-default;
          font-size: 16px;
          font-weight: 600;
          text-transform: capitalize;
          margin-bottom: 7px;
      }
      .field-input{
          margin-bottom: 20px;

          //Input Box
          input[type=text], input[type=number], input[type=email], input[type=password], input[type=url]{
            background-color: #ffffff;
            border: solid 1px #dfe3e9;
            border-radius: 4px;

            font-family: $font-proxinova-semibold;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0;
            text-align: left;
            color: #000;
            padding: 9px 13px;
            width: 100%;
          }

          //Select Box
          select{
            background-color: #ffffff;
            border: solid 1px #979797;
            font-family: $font-default;
            font-size: 19px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0;
            text-align: left;
            color: #000;
            padding: 17px 20px;
            width: 100%;
          }
          //Text Area
          textarea{
            background-color: #ffffff;
            border: solid 1px #979797;
            font-family: $font-default;
            font-size: 19px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0;
            text-align: left;
            color: #000;
            padding: 17px 20px;
            width: 100%;
            resize: vertical;
          }
          //Radio Button
            [type="radio"]:not(:checked),
            [type="radio"]:checked {
              position: absolute;
              left: -9999px;
              outline: none;
            }
            [type="radio"]:not(:checked) + label,
            [type="radio"]:checked + label {
              position: relative;
              padding-left: 28px;
              cursor: pointer;
              color: #212121;
              padding-top: 3px;
              outline: none;

            }

            /* checkbox aspect */
            [type="radio"]:not(:checked) + label:before,
            [type="radio"]:checked + label:before {
              content: '';
              position: absolute;
              left:0;
              top: 4px;
              width: 22px;
              height: 22px;
              border: 1px solid #bbb;
              border-radius: 100%;
              background: #fff;
              box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
              outline: none;

            }
            /* checked mark aspect */
            [type="radio"]:not(:checked) + label:after,
            [type="radio"]:checked + label:after {
              content: '\2022';
              position: absolute;
              line-height: 0.8;
              color: $blue;
              transition: all .2s;
              outline: none;
              top: -2px;
              left: 1.5px;
              font-size: 35px;
              outline: none;
            }
            /* checked mark aspect changes */
            [type="radio"]:not(:checked) + label:after {
              opacity: 0;
              transform: scale(0);
            }
            [type="radio"]:checked + label:after {
              opacity: 1;
              transform: scale(1);
            }
            /* disabled checkbox */
            [type="radio"]:disabled:not(:checked) + label:before,
            [type="radio"]:disabled:checked + label:before {
              box-shadow: none;
              border-color: #bbb;
              background-color: #ddd;
              outline: none;
            }
            [type="radio"]:disabled:checked + label:after {
              color: #999;
              outline: none;
            }
            [type="radio"]:disabled + label {
              color: #aaa;
              outline: none;
            }
            /* accessibility */
            [type="radio"]:checked:focus + label:before,
            [type="radio"]:not(:checked):focus + label:before {
              border: 1px dotted $blue;
            }
            [type="radio"] + label {
              display: block;
            }

          /////Checkbox
          [type="checkbox"]:not(:checked),
          [type="checkbox"]:checked {
            position: absolute;
            left: -9999px;
            outline: none;
          }
          [type="checkbox"]:not(:checked) + label,
          [type="checkbox"]:checked + label {
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            color: #212121;
            padding-top: 3px;
            outline: none;

          }

          /* checkbox aspect */
          [type="checkbox"]:not(:checked) + label:before,
          [type="checkbox"]:checked + label:before {
            content: '';
            position: absolute;
            left:0;
            top: 4px;
            width: 22px;
            height: 22px;
            border: 1px solid #bbb;
            border-radius: 0;
            background: #fff;
            box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
            outline: none;

          }content: ':heavy_check_mark:';
          /* checked mark aspect */
          [type="checkbox"]:not(:checked) + label:after,
          [type="checkbox"]:checked + label:after {
            content: '\f00c';
            position: absolute;
            line-height: 0.8;
            color: $blue;
            transition: all .2s;
            outline: none;
            top: 10px;
            left: 4px;
            font-size: 14px;
            outline: none;
            font-family: FontAwesome;
          }
          /* checked mark aspect changes */
          [type="checkbox"]:not(:checked) + label:after {
            opacity: 0;
            transform: scale(0);
          }
          [type="checkbox"]:checked + label:after {
            opacity: 1;
            transform: scale(1);
          }
          /* disabled checkbox */
          [type="checkbox"]:disabled:not(:checked) + label:before,
          [type="checkbox"]:disabled:checked + label:before {
            box-shadow: none;
            border-color: #bbb;
            background-color: #ddd;
            outline: none;
          }
          [type="checkbox"]:disabled:checked + label:after {
            color: #999;
            outline: none;
          }
          [type="checkbox"]:disabled + label {
            color: #aaa;
            outline: none;
          }
          /* accessibility */
          [type="checkbox"]:checked:focus + label:before,
          [type="checkbox"]:not(:checked):focus + label:before {
            border: 1px dotted $blue;
          }
          [type="checkbox"] + label {
            display: block;
          }

          .inputfile {
            height: 0.1px;
            opacity: 0 !important;
            overflow: hidden;
            position: absolute;
            width: 0.1px !important;
            z-index: -1;
          }

      }

  }

  //PlaceHolder Styles
  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #b5b5b5;
  }
  input::-moz-placeholder { /* Firefox 19+ */
    color: #b5b5b5;
  }
  input:-ms-input-placeholder { /* IE 10+ */
    color: #b5b5b5;
  }
  input:-moz-placeholder { /* Firefox 18- */
    color: #b5b5b5;
  }

  //Turn Off Number Input Spinners
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}


table{

  thead{
    background-color: #f5f8fa;
    border: solid 1px #e6eaee;
    font-family: $font-proxinova-semibold;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    color: #7f8fa4;
  }

  tr{
    td{
      font-family: $font-proxinova-semibold;
      font-size: 14px;
      font-weight: normal;
      text-align: left;
      color: #000000;

      i{
        color: #19a5d6;
        padding-right: 2px;
      }

      img{
        width: 56px;
        height: 56px;
        border-radius: 12px;
        margin-right: 12px;
      }

      .btn{
        font-size: 12px;
        padding: 7px 15px;
        margin-bottom: 0;
      }
    }
  }

  .status-td{
    font-family: $font-proxinova-light;
    font-size: 12px;
    text-align: left;
    color: #354052;
  }
  .action-td{
    padding-top: 5px;
    padding-bottom: 5px;
  }
  select{
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
    border: solid 1px #ced0da;
    border-radius: 4px;
    font-family: $font-proxinova-semibold;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.36;
    text-align: left;
    color: #354052;
    padding: 8px 14px;
  }
}

