.titan-accordion {
    margin: 0;
    padding: 0;
    background: #2196F3;
}

.titan-accordion__submenu {
    margin: 0;
    padding: 0;	
    .titan-accordion__li { 
        background: #1976D2;
    }
}

.titan-accordion__li { 
    border-top: 1px solid #fff;
    list-style: none;
}

.titan-accordion--child-1 { text-indent: 1em; }
.titan-accordion--child-2 { text-indent: 2em; }
.titan-accordion--child-3 { text-indent: 3em; }

.titan-accordion--clickable {
    /*background: url($img-url + 'accordion-arrow.png') no-repeat 96% 10px;*/
    cursor: pointer;
}

.titan-accordion--shown { 
    /*background: url($img-url + 'accordion-arrow.png') no-repeat 96% -70px;*/
}

.titan-accordion__title { 
    height: 48px;
    line-height: 48px;
    display: block;
}

.titan-accordion__title {
    a {
        color: #fff;
        display: block;
        padding: 0 1em;
        width: 84%;
    }
}

